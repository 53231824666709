<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-md-9">
        <div class="card card-custom card-sticky" id="kt_page_sticky_card">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Add New Post</h3>
            </div>
            <CardToolbar
              @submitEvent="submit"
              back-text="Back to list"
              back-url="/posts/list"
              button-text="Add Post"
            />
          </div>
          <div class="card-body">
            <!--begin::Form-->
            <form class="form" id="kt_form">
              <div class="form-group">
                <label for="postTitle">Title</label>
                <input
                  v-model="post.title"
                  id="postTitle"
                  class="form-control form-control-solid"
                  placeholder="Enter Post title"
                />
              </div>
              <div class="form-group">
                <label for="postSlug">Slug</label>
                <input
                  v-model="post.slug"
                  id="postSlug"
                  class="form-control form-control-solid"
                  placeholder="Enter Post Slug"
                />
              </div>
              <div class="form-group">
                <label for="postSubtitle">Subtitle</label>
                <input
                  v-model="post.subtitle"
                  id="postSubtitle"
                  class="form-control form-control-solid"
                  placeholder="Enter Post subtitle"
                />
              </div>
              <div class="form-group">
                <label for="postShortDescription">Short Description</label>
                <textarea
                    v-model="post.shortDescription"
                    id="postShortDescription"
                    class="form-control form-control-solid"
                    placeholder="Enter Post Short Description">
                </textarea>
              </div>
              <div class="form-group">
                <label for="postContent">Content</label>
                <quill-editor
                  id="postContent"
                  class="post-content"
                  :content="post.content"
                  :options="editorOption"
                  @change="onEditorChange($event)"
                />
              </div>
              <div class="separator separator-dashed my-10"></div>
              <b-card no-body class="border-0">
                <b-tabs pills vertical nav-wrapper-class="col-3">
                  <b-tab active>
                    <template v-slot:title>
                      <span class="nav-icon">
                        <i class="flaticon2-expand"></i>
                      </span>
                      <span class="font-weight-bolder">Multimedia</span>
                    </template>
                    <b-card-text>
                      <div class="card card-custom gutter-b">
                        <div class="card-header border-0">
                          <div class="card-title">
                            <h3 class="card-label">Multimedia</h3>
                          </div>
                        </div>
                        <div class="card-body pt-0">
                          <div class="form-group mb-2">
                            <label class="font-weight-bolder"
                              >Featured Image</label
                            >
                            <vue2Dropzone
                              class="dropzone dropzone-default dropzone-primary dz-clickable"
                              ref="myVueDropzone"
                              @vdropzone-file-added="filesAdded"
                              @vdropzone-complete="complete"
                              @vdropzone-success="success"
                              id="dropzone"
                              :options="dropzoneOptions"
                              :useCustomSlot="true"
                            >
                              <div class="dropzone-msg dz-message needsclick">
                                <h3 class="dropzone-msg-title">
                                  Ovdje prenesite slike ili kliknite da biste je
                                  prenijeli.
                                </h3>
                                <span class="dropzone-msg-desc"
                                  >Prenesite do 1 slika</span
                                >
                              </div>
                            </vue2Dropzone>
                          </div>
                          <div
                            v-if="post.featuredImage.src"
                            class="d-flex mb-8"
                          >
                            <div
                              class="symbol symbol-70 flex-shrink-0 mr-4 bg-light"
                            >
                              <div
                                class="symbol-label"
                                :style="{
                                  'background-image':
                                    'url(' +
                                    appPath +
                                    '/media/cache/resolve/50x50/' +
                                    post.featuredImage.src +
                                    ')'
                                }"
                              ></div>
                            </div>
                          </div>
                          <div>
                            <button
                              @click.prevent="
                                deleteFeaturedImage(post.featuredImage.src)
                              "
                              class="btn btn-primary btn-block"
                            >
                              Remove Featured Image
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-tab>

                  <b-tab>
                    <template v-slot:title>
                      <span class="nav-icon">
                        <i class="flaticon2-rocket-1"></i>
                      </span>
                      <span class="font-weight-bolder">SEO</span>
                    </template>
                    <b-card-text>
                      <Seo :meta="post.seoMeta" :entity-url="post.slug" />
                    </b-card-text>
                  </b-tab>
                </b-tabs>
              </b-card>
            </form>
            <!--end::Form-->
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Options</h3>
            </div>
          </div>
          <div class="card-body">
            <div class="row flex justify-content-between">
              <label class="col-6 col-form-label">Publish</label>
              <MCSwitch
                @checkedEvent="toggleIsPublish"
                classes="switch-icon float-right"
                name="isPublish"
                :is-checked="post.isPublish"
              />
            </div>

            <div class="row flex justify-content-between">
              <label class="col-6 col-form-label">Featured</label>
              <MCSwitch
                @checkedEvent="toggleIsFeatured"
                classes="switch-icon float-right"
                name="isFeatured"
                :is-checked="post.isFeatured"
              />
            </div>
          </div>
        </div>

        <div class="card card-custom">
          <div class="card-header border-0">
            <div class="card-title">
              <h3 class="card-label">Taxonomies</h3>
            </div>
          </div>
          <div class="card-body py-0">
            <div class="form-group">
              <label>Category</label>
              <v-select
                class="form-control form-control--category-select"
                placeholder="Select category..."
                v-model="post.category"
                :options="categories"
                :reduce="category => category['@id']"
                label="title"
              />
            </div>
            <div class="form-group">
              <label>Tags</label>
              <v-select
                multiple=""
                placeholder="Select tags..."
                class="form-control form-control--tags-select"
                v-model="post.tags"
                :options="tags"
                :reduce="tag => tag['@id']"
                label="title"
              />
              <b-button
                @click="showModal"
                class="btn btn-light-primary btn-block mt-3"
                >Add New Tag</b-button
              >
              <b-modal id="add-tag-modal" centered hide-header hide-footer>
                <template>
                  <div class="form-group mb-2">
                    <div class="input-group">
                      <input
                        id="tagName"
                        type="text"
                        v-model="newTag"
                        placeholder="Tag name"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <button
                    @click="submitNewTag"
                    class="btn btn-light-primary font-weight-bold btn-block mt-4"
                  >
                    Add
                  </button>
                </template>
              </b-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import MCSwitch from '@/view/content/components/switch/MCSwitch'
import CardToolbar from '@/view/content/components/card-toolbar/CardToolbar'
import Seo from '@/view/content/components/seo/Seo'
import vSelect from 'vue-select'
import ApiService from '@/core/services/api.service'
import vue2Dropzone from 'vue2-dropzone'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  name: 'NewPost',
  components: {
    MCSwitch,
    CardToolbar,
    Seo,
    vSelect,
    vue2Dropzone,
    quillEditor
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    }
  },
  data() {
    return {
      post: {
        title: '',
        slug: '',
        subtitle: '',
        shortDescription: '',
        content: '',
        featuredImage: {
          src: '',
          alt: ''
        },
        imageGallery: [],
        tags: [],
        seoMeta: {
          ogTitle: '',
          description: '',
          keywords: ''
        },
        isPublish: true,
        isFeatured: false
      },
      categories: [],
      tags: [],
      newTag: '',
      dropzoneOptions: {
        url: process.env.VUE_APP_API_ENDPOINT + '/upload',
        thumbnailWidth: 150,
        maxFilesize: 1
      },
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['clean', 'link']
          ]
        }
      },
      appPath: process.env.VUE_APP_ROUTE_API_URL
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Posts', route: '/posts/list' },
      { title: 'Add New' }
    ])

    this.getCategories().then(({ data }) => {
      this.categories = [...data['hydra:member']]
    })

    this.getTags().then(({ data }) => {
      this.tags = [...data['hydra:member']]
    })
  },
  methods: {
    toggleIsPublish() {
      this.isPublish = !this.isPublish
    },
    toggleIsFeatured() {
      this.isFeatured = !this.isFeatured
    },
    async submit() {
      this.postPost(this.post)
        .then(() => {
          this.$notify({
            group: 'notification',
            type: 'success',
            title: 'Post success added'
          })

          setTimeout(() => {
            this.$router.push({ name: 'posts-list' })
          }, 500)
        })
        .catch(() => {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Post error'
          })
        })
    },
    async postPost(entity) {
      return await ApiService.post('/posts', entity)
    },
    async getCategories() {
      return await ApiService.get('/post_categories')
    },
    async getTags() {
      return await ApiService.get('/tags')
    },
    showModal() {
      this.$bvModal.show('add-tag-modal')

      setTimeout(() => {
        document.getElementById('tagName').focus()
      }, 0)
    },
    submitNewTag() {
      this.postTag(this.newTag).then(({ data }) => {
        this.tags.push(data)
        this.post.tags.push(data['@id'])
        this.$bvModal.hide('add-tag-modal')
        this.newTag = ''
      })
    },
    async postTag(tag) {
      return await ApiService.post('/tags', { title: tag })
    },
    filesAdded() {},
    success(_, response) {
      this.post.featuredImage.src = '/uploads/' + response
    },
    complete() {},
    deleteFeaturedImage(filename) {
      const confirmation = confirm('Do you want to delete Featured Image?')
      if (confirmation === false) return

      this.post.featuredImage.src = ''
      ApiService.post('delete/' + filename.replace('/uploads/', ''))
    },
    onEditorBlur() {},
    onEditorFocus() {},
    onEditorReady() {},
    onEditorChange({ html }) {
      this.post.content = html
    }
  }
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control--category-select,
.form-control--tags-select {
  height: unset !important;

  .vs__dropdown-toggle {
    padding: 0;
    border: 0;
    border-radius: 0;
  }
  .vs__dropdown-menu {
    border: 1px solid #e4e6ef;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    li {
      padding: 0.65rem 1rem;

      &:hover {
        background: #f3f6f9;
        color: #3f4254;
      }
    }
  }

  .vs__open-indicator {
  }
  .vs__dropdown-toggle {
  }
  .vs__clear {
    display: none;
  }
  .vs__selected {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    font-size: 1rem;
    margin: 0.1rem 0.4rem 0.4rem 0;
    position: relative;
    float: left /*rtl:right*/;
    color: #3f4254;
    background: #f3f6f9;
    border: 0;
    border-radius: 0.42rem;
  }
  .vs__no-options {
  }
  .vs__spinner {
  }
  .vs__deselect {
  }
  .vs__active {
  }
  .vs__dropdown-option--highlight {
    background: #ebedf3;
    color: #3f4254;
  }
}
.ql-editor {
  min-height: 400px;
}
</style>
